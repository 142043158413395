import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { StyledLocationSelector, StyledList } from './styles';
import CurrencyFlag from 'react-currency-flags';



export const TicketLinks: React.StatelessComponent<any> = () => (
  <StaticQuery
    query={graphql`
      query TicketLinks {
        wp {
          acfOptionsThemeSettings {
            themeOptions {
              ticketsPageCta
              ticketLinks {
              currency
              link {
                url
                title
                target
              }
            }
          }
        }
      }
    }`}
    render={({wp: { acfOptionsThemeSettings: { themeOptions : {ticketLinks, ticketsPageCta} }}}) => {
      if(ticketLinks && ticketLinks.length > 1) {
      return (
      <StyledLocationSelector>
        <span>{ticketsPageCta || `BUY`}</span>
        <StyledList>
        {ticketLinks.map(({link, currency}, index) => {
          return <li key={`ticket-link-${index}`}><a href={link.url} target={link.target ? link.target : '_blank'}><CurrencyFlag currency={currency} />{link.title}</a></li>
        })}
        </StyledList>
      </StyledLocationSelector>
    )}
    return null;
  }}
  />
);

export default TicketLinks;
 