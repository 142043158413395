import React from 'react';
import HeroBlock from 'blocks/Hero';
import BlockPage from 'layout/components/BlockPage';
import BlockSection from 'sections/Block';
import Layout from 'components/Layout';
import TicketLinks from 'components/TicketLinks';
import {  DFPManager } from 'react-dfp';
import { FooterAd } from 'components/FooterAd';

class Page extends BlockPage {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    setTimeout(() => {
      DFPManager.load();
    }, 5000);
  }
  renderChildren() {
    const { content } = this.props;

    
    return (
      <>
        {content && (
          <>
            {content.title && (
              <>
              <BlockSection isFirstSection={true} pageHasAnnouncement={this.pageHasAnnouncement()} color="lime">
                <HeroBlock title={content.title}
                leadingParagraph={content.pageOptions && content.pageOptions.leadingparagraph}
                center={true} />
                  <div style={{
                    textAlign: 'center'
                  }}>
                      <TicketLinks />
                  </div>
              </BlockSection>
              </>
            )}
            <BlockSection isFirstSection={!content.title} pageHasAnnouncement={this.pageHasAnnouncement()}>
            <Layout.PageContainer hasSidebar={false}>
              <Layout.Content>
                <Layout.LayoutInner>
                  {content.flexibleContent &&
                    content.flexibleContent.contentBlocks &&
                    content.flexibleContent.contentBlocks.map((contentBlock, index) => {
                      return (
                        <BlockSection key={index}>
                          {this.renderBlock(contentBlock, index)}
                        </BlockSection>
                      );
                    })}
                    <BlockSection style={{ textAlign: 'center'}}>
                      <FooterAd />
                    </BlockSection>
                </Layout.LayoutInner>
              </Layout.Content>
              </Layout.PageContainer>
            </BlockSection>
          </>
        )}
      </>
    );
  }
}

export default Page;
